import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Podcast() {
  return (
    <section id="podcast" className="podcast">
      <div className="podcast__blurb u__container u__vspacing u__spacing">
        <a href="//thirtythousandleagues.com" className="h4 mb-0" target="_blank" rel="nofollow noreferrer">Check out our podcast</a>
        <h2 className="display-4">30,000 Leagues</h2>
        <div className="podcast__badges">
          <a href="//podcasts.apple.com/us/podcast/30-000-leagues/id1476890683" target="_blank" rel="nofollow noreferrer" title="Open Apple Podcasts in a new window">
            <StaticImage src="../images/podcasts-white-trans_apple.svg" alt="Listen with Apple Podcasts" layout="fixed" width={165} height={40} />
          </a>
          <a href="//podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkLnBpcHBhLmlvL3B1YmxpYy9zaG93cy90aGlydHktdGhvdXNhbmQtbGVhZ3Vlcw&ved=0CAAQ4aUDahcKEwjwjNKlzKHoAhUAAAAAHQAAAAAQAQ&hl=en" target="_blank" rel="nofollow noreferrer" title="Open Google Podcasts in a new window">
            <StaticImage src="../images/podcasts-white-trans_google.svg" alt="Listen with Google Podcasts" layout="fixed" width={165} height={40} />
          </a>
          <a href="//open.spotify.com/show/0pItMizm0y0ndIkiV2kkgu" target="_blank" rel="nofollow noreferrer" title="Open Spotify in a new window">
            <StaticImage src="../images/podcasts-white-trans_spotify.svg" alt="Listen with Spotify" layout="fixed" width={165} height={40} />
          </a>
        </div>
      </div>
      <div className="podcast__img">
        <StaticImage src="../images/illus/30kleagues-3200.jpg" alt="" layout="fullWidth" />
      </div>
    </section>
  )
}
