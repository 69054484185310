import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Cityscape(props) {
  return (
    <div
      className={`cityscape${props.sky ? " s__sky" : ""}${props.splatter ? " s__lil-splatter" : ""}`}
      aria-hidden="true"
    >
      <div className="city--header">
        <div className="cloud cloud--1"></div>
        <div className="cloud cloud--2"></div>
        <div className="cloud cloud--3"></div>
        <div className="cloud cloud--4"></div>
        <div className="cloud cloud--5"></div>
        <StaticImage src="../images/illus/cityscape-3200.png" alt="" layout="fullWidth" />
      </div>
    </div>
  )
}
