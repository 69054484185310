import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import Mailchimp from "../Mailchimp"

import styled from 'styled-components'

const SingleLineSpan = styled.span`
  display: inline-block;
`

const OtherFooterLink = styled.a`
  display: inline;
  text-decoration: none;
  margin-right: 0.5rem;
`

export default function Footer() {
  return (
    <footer id="anchor-contact" className="main__footer footer s__dark u__vspacing target-cynosure">
      <div className="u__container u__spacing">
        <div className="row align-items-start">
          <div className="col-md-6 col-lg-7 col-xl-8">
            <div className="footer__logo text-center text-md-left">
              <StaticImage
                src="../../images/bpl-shield-white.svg"
                alt="Brown University"
                layout="fixed"
                width={140}
                height={70}
                className="brand__img"
              />
            </div>
            <div className="text-center text-md-left">
              <address className="text-center text-md-left">
                <a href="https://www.google.com/maps/place/225+Dyer+St,+Providence,+RI+02903/data=!4m2!3m1!1s0x89e44515bb1f3ad9:0x32a107a554a713c0?sa=X&ved=2ahUKEwi3iqjcgevnAhVpUN8KHf9yCcYQ8gEwAHoECAsQAQ" target="_blank" rel="nofollow noreferrer">
                  <span>225 Dyer Street, 5th floor</span>
                  <span>Providence RI 02903</span>
                </a>
              </address>

              <SingleLineSpan>
                  <OtherFooterLink href="https://twitter.com/ThePolicyTweets"><FontAwesomeIcon icon={faTwitter} size="1x" /></OtherFooterLink>
                  <OtherFooterLink href="https://www.linkedin.com/company/thepolicylab/"><FontAwesomeIcon icon={faLinkedin} size="1x" /></OtherFooterLink>
              </SingleLineSpan>
            </div>
            <p className="text-center text-md-left">
              <a href="mailto:thepolicylab@brown.edu" target="_blank" rel="nofollow noreferrer">thepolicylab@brown.edu</a>
              <a className="breaker" href="tel:401-863-3392" target="_blank" rel="nofollow noreferrer">(401) 863-3392</a>
            </p>
            <p className="text-center text-md-left m-0">
              <small>An official website of Brown University</small>
            </p>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4 pt-5 pt-md-0">
            <Mailchimp id="footer" />
          </div>
        </div>
      </div>
    </footer>
  )
}
