import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"

export default function Navigation() {
  const { pathname } = useLocation()
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  return (
    <StaticQuery
      query={graphql`
        {
          contentfulNavigation(title: {eq: "Main Navigation"}) {
            navigationItems {
              navigationItem {
                contentful_id
                displayText
                link
              }
              navigationSubItems {
                contentful_id
                displayText
                link
              }
            }
          }
        }
      `
      }
      render={data => (
        <div className="nav__container fixed-top">
          <nav id="main-nav" className="main__nav navbar navbar-expand-lg u__container nav" aria-label="Main navigation">
            <a href="#content" className="nav-link nav__link nav__skip sr-only sr-only-focusable">Skip to main content</a>
            <button className="navbar-toggler nav__button" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="nav__button__icon">&equiv;</span>
              <span className="nav__button__text"><span className="sr-only">Open the </span>Menu</span>
            </button>
            <div className="collapse navbar-collapse nav__pane" id="navbarContent">
              <ul className="navbar-nav nav__list">
                {data.contentfulNavigation.navigationItems.map((navigationItem) => {
                  const item = navigationItem.navigationItem
                  const submenu = navigationItem.navigationSubItems !== null
                  const hasActiveChildren = pathname.includes(item.displayText.replace(" ", "-").toLowerCase()) ? "active" : ""
                  const anchorLink = item.link.match(/#.+/) !== null
                  return (
                    <li className={`nav-item nav__item ${submenu ? "dropdown" : ""}`} key={item.contentful_id}>
                      {submenu &&
                        <>
                          <button onClick={() => setIsNavCollapsed(!isNavCollapsed)} className={`nav-link nav__link dropdown-toggle ${hasActiveChildren}`} type="button" id={`dropdown-${item.contentful_id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {item.displayText}
                          </button>
                          <ul className="navbar-nav nav__list subnav__list dropdown-menu" aria-labelledby={`dropdown-${item.contentful_id}`}>
                            {navigationItem.navigationSubItems.map((navigationSubItem) => {
                              return (
                                <li className="nav-item nav__item dropdown-item" key={navigationSubItem.contentful_id}>
                                  <Link to={navigationSubItem.link} className="nav-link nav__link" activeClassName="active" tabIndex={isNavCollapsed ? "-1" : "0"}>{navigationSubItem.displayText}</Link>
                                </li>
                              )
                            })}
                          </ul>
                        </>
                      }
                      {(!submenu && anchorLink) &&
                        <a href={item.link} className="nav-link nav__link">
                          {item.displayText}
                        </a>
                      }
                      {(!submenu && !anchorLink) &&
                        <Link to={item.link} className="nav-link nav__link" activeClassName="active">
                          {item.displayText}
                        </Link>
                      }
                    </li>
                  )
                })}
                <li className="nav-item nav__item" key={`get-updates`}>
                  <a href="#get-updates" className="nav-link nav__link">Get Updates</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    />
  )
}
