import React from "react"
import Navigation from "./Navigation"
import Footer from "./Footer"
import Cityscape from "../Cityscape"
import Podcast from "../Podcast"
import "../../styles/styles.scss"

const Layout = ({ children }) => {
  return (
    <div id="top" className="l-wrapper">
      <Navigation />
      {children}
      <Cityscape sky={true} splatter={true} />
      <Podcast />
      <Footer />
    </div>
  )
}

export default Layout
