import React from "react"

export default function Mailchimp(props) {
  return (
    <div id={`anchor-signup-${props.id}`} className="mc">
      <div id={`mc_embed_signup${props.id}`}>
        <h2 className="h6 text-center text-md-left">Subscribe</h2>
        <form action="https://brown.us20.list-manage.com/subscribe/post?u=5050b7f10df36f1c41b3743cf&amp;id=cf85e54967" method="post" id={`mc-embedded-subscribe-form-${props.id}`} name="mc-embedded-subscribe-form" className="validate form-inline mc__form justify-content-center justify-content-md-start" target="_blank">
          <div id={`mc_embed_signup_scroll-${props.id}`} className="form-group mc__wrapper">
            <label htmlFor="mce-EMAIL" className="mc__label">
              <span className="sr-only">Please provide your email address:</span>
              <input type="email" name="EMAIL" className="form-control mc__input" id={`mce-EMAIL-${props.id}`} placeholder="name@email.com" defaultValue="" required />
            </label>
            <div className="mc__nectar" aria-hidden="true">
              <input type="text" name="b_5050b7f10df36f1c41b3743cf_cf85e54967" tabIndex="-1" defaultValue="" />
            </div>
            <input type="submit" name="subscribe" id={`mc-embedded-subscribe-${props.id}`} className="btn btn-info mc__button" value="Submit" />
          </div>
        </form>
      </div>
    </div>
  )
}
