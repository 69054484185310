import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import faviconMask from "../images/favicons/safari-pinned-tab.svg"

const SEO = ({ lang, title, description, image, article, bodyClass }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{lang}} bodyAttributes={{class: bodyClass}}>
      <meta name="description" content={seo.description} />

      <link rel="dns-prefetch" href="//use.typekit.net" />
      <link rel="dns-prefetch" href="//cloud.typography.com" />
      <link rel="dns-prefetch" href="//www.googletagmanager.com" />
      <link rel="dns-prefetch" href="//code.jquery.com" />
      <link rel="dns-prefetch" href="//stackpath.bootstrapcdn.com" />
      <link rel="preconnect" href="//brown.us20.list-manage.com" />
      <link rel="canonical" href={seo.url} />

      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="google-site-verification" content="wkcAVWy8ClxzqSm5y4O1zgeSVSi3QBaFD69VQP5YNvQ" />

      {seo.url &&
        <meta property="og:url" content={seo.url} />
      }

      {seo.title &&
        <meta property="og:title" content={seo.title} />
      }
      
      {seo.description &&
        <meta property="og:description" content={seo.description} />
      }
      
      {seo.image &&
        <meta property="og:image" content={seo.image} />
      }
      
      <meta property="og:type" content={article ? `article` : `website`} />
      <meta name="twitter:card" content="summary_large_image" />
      
      {seo.title &&
        <meta name="twitter:title" content={seo.title} />
      }
      
      {seo.description &&
        <meta name="twitter:description" content={seo.description} />
      }
      
      {seo.image &&
        <meta name="twitter:image" content={seo.image} />
      }
      {/* See also gatsby-plugin-manifest in gatsby-config.js */}
      <link rel="mask-icon" href={faviconMask} color="#007ea8" />
      <meta name="msapplication-TileColor" content="#007ea8" />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  lang: `en`,
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
      }
    }
  }
`
